import { selector } from "recoil";
import IsMobile from "./IsMobile";
import NavHeight from "./NavHeight";

export default selector<number>({
  key: "NetNavHeight",
  get: ({ get }) => {
    const isMobile = get(IsMobile);
    const navHeight = get(NavHeight);
    if (isMobile) {
      return 0;
    } else {
      return navHeight;
    }
  },
});
