import React from "react";
import { useRecoilValue } from "recoil";
import { animated, useSpring } from "@react-spring/web";
import styled from "styled-components";
import Container from "@material-ui/core/Container";

import NetNavHeight from "src/state/NetNavHeight";

const spacing = 6;

const Main = animated(styled(Container).attrs({ component: "main" })<{
  navheight: number;
}>`
  margin-top: ${({ navheight }) => `${navheight}px`};
  padding-bottom: ${({ theme }) => `${theme.spacing(spacing)}px`};
  padding-top: ${({ theme }) => `${theme.spacing(spacing)}px`};
`);

export default (props: any) => {
  const navHeight = useRecoilValue(NetNavHeight);
  const spring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    reset: true,
  });
  return <Main navheight={navHeight} {...props} style={spring} />;
};
